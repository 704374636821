
import Joi from 'joi';
import { useRouter } from 'vue-router';
import { defineComponent, ref, onMounted } from 'vue';
import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  IonLabel,
  IonFooter,
  IonItem,
} from '@ionic/vue';
import DlvrInput from '@/components/DlvrInput.vue';
import { validateFields } from '@/lib/forms';
import { loading, openToast } from '@/composables/ionicControllers';

import { chevronBackOutline } from 'ionicons/icons';

import { auth } from '@/store';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    DlvrInput,
    IonButton,
    IonIcon,
    IonLabel,
    IonFooter,
    IonItem,
  },
  setup() {
    onMounted(async () => {
      const userToken: any = localStorage.getItem('user-token');
      if (userToken) {
        await router.push('/app');
      }
    });
    const { passwordLogin } = auth;

    const identifier = ref('');
    const password = ref('');

    const router = useRouter();

    const validationRules = {
      'Email or phone number': Joi.string()
        .min(5)
        .required(),
      password: Joi.string()
        .min(4)
        .required(),
    };

    const goBack: () => void = () =>
      window.location.replace('https://dlvr.ng');

    const schema = Joi.object(validationRules);

    const onLogin = async () => {
      const { error, value } = validateFields(
        schema,
        () => ({
          'Email or phone number': identifier.value,
          password: password.value,
        }),
        false,
      );

      if (error) {
        console.log('error: ', error);
      } else {
        const loader = await loading();
        await loader.present();

        const response: any = await passwordLogin(
          value['Email or phone number'],
          value.password,
        );

        if (!response.ok) {
          await openToast(response?.message);
          await loader.dismiss();
        } else {
          await loader.dismiss();
          await openToast(response?.message || 'Login Successful');
          await router.push('/app');
        }

        await loader.dismiss();
      }
    };
    return {
      chevronBackOutline,
      validationRules,
      router,
      identifier,
      password,
      onLogin,
      goBack,
    };
  },
});
